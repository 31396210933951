import React from "react";

export interface ProductsProps {}

export const Products: React.FC<ProductsProps> = ({}) => {
  return (
    <div className="prose prose-slate prose-headings:font-['SF-UI-Display'] prose-p:font-['SF-UI-Display'] my-3 blogBody text-left">
      <h1>Our Products</h1>
      <p>Products we have created.</p>
      <h3>Book My Transfer</h3>
      <p>
        <a href="https://bmt.mv" target="_blank">
          bmt.mv
        </a>{" "}
        is a speed boat transfer management application made with convenience in
        mind, both for the operator and customer.
      </p>
      <img src="/bmt.png" alt="Logo for Book My Transfer" />
      <h3>Room Radar</h3>
      <p>
        <a href="https://roomradar.mv" target="_blank">
          roomradar.mv
        </a>{" "}
        is a platform for hotel agents to quickly check availability of rooms.
      </p>
      <img src="/roomradar.svg" alt="Logo for Room Radar" />
    </div>
  );
};
