import React from "react";

export interface ClientsProps {}

export const Clients: React.FC<ClientsProps> = ({}) => {
  return (
    <div className=" my-3 blogBody text-left font-[SF-UI-Display]">
      <h1 className="font-extrabold text-4xl mb-4  mb-[0.89em]">Our Clients</h1>
      <p className="my-[1.25em]">Clients we have developed solutions for.</p>
      <div className="flex justify-around flex-wrap w-full gap-12 mt-12">
        <img
          src="/tma.png"
          alt="Logo for Trans Maldivian Airways"
          title="Trans Maldivian Airways"
        />
        <img
          src="/bmt-refcool.png"
          alt="Logo for Refcool Marine"
          title="Refcool Marine"
        />
        <img
          src="/bmt-mam-text.png"
          alt="Logo for MA Marine"
          title="MA Marine"
        />
        <img
          src="/bmt-niru-text.png"
          alt="Logo for Niru Travels"
          title="Niru Travels"
        />
        <img
          src="/bmt-ayala-text.png"
          alt="Logo for Ayala"
          title="Ayala Travel & Tours"
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/bmt-altec-text.png"
            alt="Logo for Altec Tours"
            title="Altec Tours"
          />
        </div>
      </div>
    </div>
  );
};
