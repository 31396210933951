import axios, { Method } from "axios";

export default async function request(
  endpoint: string,
  method: Method,
  data?: object,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
): Promise<[any, string]> {
  if (setLoading) {
    setLoading(true);
  }
  try {
    const resp = await axios(
      `${process.env.REACT_APP_CMS_URL ?? ""}/api/${endpoint}`,
      {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      }
    );
    return [resp, ""];
  } catch (e: any) {
    console.log(e);
    return [null, "Unexpected error occured."];
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
}
