import React from "react";
import { useNavigate } from "react-router-dom";

export interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex justify-center mt-3 mb-3 items-center">
        <div onClick={() => navigate("/")} className="cursor-pointer">
          <div className="flex justify-center items-center">
            <img
              className="h-12"
              src="/logo.png"
              alt="Logo for techverin.com"
            />
          </div>
          <div className="font-['SF-UI-Display']">
            Ma. Malikuview, 8F, Majeedhee Magu
          </div>
        </div>
      </div>
    </div>
  );
};
