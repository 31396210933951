import React from "react";
import { Link } from "react-router-dom";

export interface FooterProps {}

export const Footer: React.FC<FooterProps> = ({}) => {
  return (
    <div className="font-['SF-UI-Text'] text-sm mt-6">
      <hr className="my-2"></hr>
      <div className="my-2 text-justify opacity-70">
        Techverin Private Limited is a software company based in the Maldives.
        We help businesses automate their offline processes to increase
        productivity and revenue. Contact us if you have any inquiries.{" "}
      </div>
      <hr className="my-2"></hr>
      <div className="flex justify-between mb-2">
        <div>
          <span className="font-bold mr-1">{new Date().getFullYear()}</span>
          Techverin Pvt. Ltd
        </div>
        <div>
          <Link to="/contact" className="underline mr-3">
            Contact Us
          </Link>
          <Link to="/about" className="underline">
            About Us
          </Link>
        </div>
      </div>
    </div>
  );
};
