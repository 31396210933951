import React from "react";
import { Link } from "react-router-dom";

export interface BlogsProps {}

export const Blogs: React.FC<BlogsProps> = () => (
  <div className="pt-3 flex">
    <div className="flex flex-col w-full">
      <div className="rounded-lg mb-3 mx-2 hover:bg-[#44a4aa]/20 cursor-pointer font-['SF-UI-Text']">
        <Link to={`/blog/products`}>
          <div className="font-bold text-left p-2">Our Products</div>
          <div className="px-2 text-ellipsis text-left opacity-70 text-sm md:text-base">
            Products we have created.
          </div>
        </Link>
      </div>
    </div>
    <div className="flex flex-col w-full">
      <div className="rounded-lg mb-3 mx-2 hover:bg-[#44a4aa]/20 cursor-pointer font-['SF-UI-Text']">
        <Link to={`/blog/clients`}>
          <div className="font-bold text-left p-2">Our Clients</div>
          <div className="px-2 text-ellipsis text-left opacity-70 text-sm md:text-base">
            Clients we have developed solutions for.
          </div>
        </Link>
      </div>
    </div>
  </div>
);
