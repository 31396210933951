import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Home } from "./pages/Home";
import { Clients } from "./pages/blogs/Clients";
import { Products } from "./pages/blogs/Products";

function App() {
  return (
    <div className="App flex justify-center">
      <div className="w-full md:w-2/3 lg:w-1/2 p-3 pb-0 min-h-screen flex flex-col justify-between">
        <div className="flex flex-col">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog/products" element={<Products />} />
            <Route path="/blog/clients" element={<Clients />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
