import React from "react";

export interface AboutProps {}

export const About: React.FC<AboutProps> = ({}) => {
  return (
    <div>
      <div className="prose prose-slate flex flex-col items-start">
        <div className="font-['SF-UI-Display'] text-4xl mb-10">About us</div>
        <div className="underline">
          <a href={`tel:+960 7568118`}>+960 7568118</a>
        </div>
        <div className="underline">
          <a href={`mailto:hello@techverin.com`}>hello@techverin.com</a>
        </div>
        <div className="mt-3 mb-3 font-['SF-UI-Text'] text-left">
          Techverin Private Limited is a software company based in the Maldives.
        </div>
        <div className="underline">
          <a href="/registration.jpg" target="_blank">
            Business Activity Registration
          </a>
        </div>
        <div className="underline">
          <a href="/gst.pdf" target="_blank">
            GST Registration
          </a>
        </div>
      </div>
    </div>
  );
};
