import React from "react";
import { Blogs } from "../components/Blogs";

export interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  return (
    <div>
      <Blogs />
    </div>
  );
};
